import { Filter, Modal, Roles, URLS, getCurrentUser } from '@netcurio/frontend-common'
import { useNetcurioLoader } from '@netcurio/frontend-components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import { Dayjs } from 'dayjs'
import { DocumentNode } from 'graphql'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthenticatedHeader } from '../../../components/AuthenticatedHeader/AuthenticatedHeader'
import { AddButton } from '../../../components/HeaderButtons/AddButton'
import { ExportButton } from '../../../components/HeaderButtons/ExportButton'
import { useCompanySettings } from '../../../hooks/useCompanySettings'
import '../../../style.scss'
import { PurchaseOrder } from '../../../types'
import { connection } from '../../../utilities/connection'
import Constants from '../../../utilities/constants'
import { showErrorComponent } from '../../../utilities/errorCode'
import { expiredToken } from '../../../utilities/expiredToken'
import { downloadExcelFile } from '../../../utilities/file-handling/download-excel-file'
import { FileDescriptor } from '../../../utilities/file-handling/file-descriptor'
import listHelper from '../../../utilities/listHelper'
import UserInformation from '../../../utilities/userInformation'
import { CatchedErrorModalPurchaseOrderList } from './Modals/CatchedErrorModalPurchaseOrderList'
import { PurchaseOrderTable } from './purchaseOrderTable'
import * as queries from './queries'
import { objectStatusFilterArray, objectTypeFilterArray } from './tableParameterArray'
import { DataPurchaseOrder, FilterToApply, PurchaseOrderList, UpdateFilter } from './types'

export const PurchaseOrdersList = (): ReactElement => {
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [showBarLoader, setShowBarLoader] = useState<boolean>()
	const currentUser = getCurrentUser()
	const initialSort = 'created_at'
	const history = useHistory<string>()
	const userRole: Roles = UserInformation.getCompanyRole()
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const containerHeightCompensation = 95
	const heightRow = 45
	const additionalRow = 2
	const [modal, setModal] = useState<Modal>({
		errorCode: undefined
	})
	const [activePagination, setActivePagination] = useState<boolean>(false)
	const [stopPagination, setStopPagination] = useState<boolean>(true)
	const [purchaseOrderList, setPurchaseOrderList] = useState<PurchaseOrderList>({
		rowsActual: undefined,
		totalRows: undefined,
		filterContainerBar: false,
		dataFiltersArray: [],
		dataFilters: {},
		deleteRange: false,
		limitRows: undefined,
		fieldList: initialSort,
		orderList: Constants.ORDER.DESC,
		actualFilterData: undefined,
		filtersOfTypeStatus: {
			type: {
				list: objectTypeFilterArray(),
				numSelectOptions: 3,
				dataFilter: ''
			},
			status: {
				list: objectStatusFilterArray(),
				numSelectOptions: 6,
				dataFilter: ''
			}
		},
		dataPurchaseOrder: {}
	})

	const { companySettings } = useCompanySettings({
		rfc: currentUser?.company?.rfc ?? null,
		errorHandler: (error: Error) => handleError(error)
	})

	const isCustomer = userRole === Roles.CUSTOMER
	const isNewOrderButtonDisabled =
		companySettings == undefined
			? false
			: isCustomer && companySettings?.process_invoice_in_external_system

	useEffect(() => {
		let finalHeight: number
		const minHeightScreen = 600
		const height = 500

		if (window.innerHeight > minHeightScreen) {
			finalHeight = window.innerHeight - containerHeightCompensation
		} else {
			finalHeight = height
		}

		const numberRows: number = Math.round(finalHeight / heightRow) + additionalRow
		setPurchaseOrderList((prevState) => ({
			...prevState,
			limitRows: numberRows,
			rowsActual: numberRows
		}))
		showLoadingSpinner()
	}, [])

	useEffect(() => {
		if (purchaseOrderList.limitRows) {
			filteringPurchaseOrder(0, initialSort, '', initialSort)
		}
	}, [purchaseOrderList.limitRows])

	useEffect(() => {
		if (activePagination && stopPagination) {
			setActivePagination(false)
			setStopPagination(false)
			setShowBarLoader(true)
			filteringPurchaseOrder(
				purchaseOrderList.rowsActual,
				'',
				'',
				'',
				undefined,
				undefined,
				undefined,
				'none'
			)
		}
	}, [activePagination, stopPagination])

	const downloadReportExcel = () => {
		if (Object.keys(purchaseOrderList.dataPurchaseOrder).length > 0) {
			showLoadingSpinner()
			const copyFilterToApply: Array<Filter> = JSON.parse(
				JSON.stringify(purchaseOrderList.dataFiltersArray)
			)
			copyFilterToApply.forEach(listHelper.applyTimeZoneDate)
			const body = JSON.stringify({
				companyFilter: currentUser ? currentUser.company.rfc : undefined,
				filters: copyFilterToApply,
				sort_field: purchaseOrderList.fieldList,
				sort_order: purchaseOrderList.orderList
			})
			listHelper
				.generateReportList(body, 'purchase-orders')
				.then(async (res) => {
					if (res.ok) {
						res.json().then((responseJson: FileDescriptor) => {
							downloadExcelFile(responseJson)
						})
						hideLoadingSpinner()
					} else {
						const error: Error = await res.json()
						handleError(error)
						hideLoadingSpinner()
					}
				})
				.catch(handleError)
		}
	}

	const queryFilteringPurchaseOrder = (
		fieldListData: string,
		orderListData: string,
		filterToApply: Array<Filter>,
		skip: number,
		currentList: DataPurchaseOrder
	) => {
		const copyFilterToApply: Array<Filter> = JSON.parse(JSON.stringify(filterToApply))
		let query: DocumentNode
		if (userRole === Roles.CUSTOMER) {
			query = queries.PURCHASE_ORDERS_CUSTOMER
		} else {
			query = queries.PURCHASE_ORDERS_SUPPLIER
		}
		copyFilterToApply.forEach(listHelper.applyTimeZoneDate)
		client
			.query({
				query: query,
				variables: {
					limit: purchaseOrderList.limitRows,
					skip: skip,
					sort_field: fieldListData,
					sort_order: orderListData,
					filter: copyFilterToApply
				}
			})
			.then((result) => {
				let interCount: number = skip
				const data: DataPurchaseOrder = { ...currentList }
				const obj: Array<PurchaseOrder> = result.data.PurchaseOrders.list

				for (const keyName in obj) {
					interCount++
					data[interCount] = obj[keyName]
				}
				setPurchaseOrderList((prevState) => ({
					...prevState,
					totalRows: result.data.PurchaseOrders.total,
					dataPurchaseOrder: data,
					filtersOfTypeStatus: {
						...prevState.filtersOfTypeStatus,
						type: {
							...prevState.filtersOfTypeStatus.type,
							dataFilter: prevState.filtersOfTypeStatus.type.dataFilter || ''
						},
						status: {
							...prevState.filtersOfTypeStatus.status,
							dataFilter: prevState.filtersOfTypeStatus.status.dataFilter || ''
						}
					}
				}))
				setStopPagination(true)
				hideLoadingSpinner()
				setShowBarLoader(false)
			})
			.catch(handleError)
	}

	const handleError = (error: Error) => {
		const errorCode: string = showErrorComponent(error)
		if (!expiredToken(errorCode)) {
			setPurchaseOrderList((prevState) => ({
				...prevState,
				dataPurchaseOrder: {},
				filtersOfTypeStatus: {
					...prevState.filtersOfTypeStatus,
					type: {
						...prevState.filtersOfTypeStatus.type,
						dataFilter: ''
					},
					status: {
						...prevState.filtersOfTypeStatus.status,
						dataFilter: ''
					}
				}
			}))
			setStopPagination(true)
			setModal({
				errorCode: errorCode
			})
		}
		hideLoadingSpinner()
	}

	const filteringPurchaseOrder = (
		skip: number,
		sortField: string,
		sortOrder: string,
		elementFilterActual: string,
		valueFilter = '',
		initRange?: number | Dayjs,
		finalRange?: number | Dayjs,
		filterRemove = ''
	) => {
		let fieldListData: string = purchaseOrderList.fieldList
		let orderListData: string = purchaseOrderList.orderList
		let columnFilterActual: string = purchaseOrderList.actualFilterData
		let currentList: DataPurchaseOrder = {
			...purchaseOrderList.dataPurchaseOrder
		}
		if (sortOrder !== '') {
			orderListData = sortOrder
			setPurchaseOrderList((prevState) => ({
				...prevState,
				orderList: sortOrder
			}))
		}
		if (sortField) {
			setPurchaseOrderList((prevState) => ({
				...prevState,
				fieldList: sortField
			}))
			fieldListData = sortField
		}
		if (elementFilterActual !== '') {
			columnFilterActual = elementFilterActual
			setPurchaseOrderList((prevState) => ({
				...prevState,
				actualFilterData: elementFilterActual
			}))
		}
		let typeFilterActual: string
		let initRangeActual: number | Dayjs
		let finalRangeActual: number | Dayjs
		const valueFilterActual: string = valueFilter

		switch (columnFilterActual) {
			case 'id':
			case 'branch_office':
			case 'customer':
			case 'supplier':
			case 'customer_reference':
				typeFilterActual = 'wildcard'
				break
			case 'status':
			case 'type':
				typeFilterActual = 'exact_match'
				break
			case 'created_at':
				typeFilterActual = 'date'
				initRangeActual = initRange
				finalRangeActual = finalRange
				break
			case 'total':
				typeFilterActual = 'numeric'
				initRangeActual = initRange
				finalRangeActual = finalRange
		}
		if (skip > 0) {
			setPurchaseOrderList((prevState) => ({
				...prevState,
				rowsActual: prevState.rowsActual + prevState.limitRows
			}))
		} else {
			setPurchaseOrderList((prevState) => ({
				...prevState,
				rowsActual: prevState.limitRows,
				dataPurchaseOrder: {}
			}))
			currentList = {}
		}
		const filterToApply: Array<Filter> = activeFilterToApply(
			typeFilterActual,
			valueFilterActual || undefined,
			initRangeActual || undefined,
			finalRangeActual || undefined,
			columnFilterActual,
			filterRemove || undefined,
			sortField
		)
		queryFilteringPurchaseOrder(fieldListData, orderListData, filterToApply, skip, currentList)
	}

	const activeFilterToApply = (
		typeFilterActual: string,
		valueFilterActual: string,
		initRangeActual: number | Dayjs,
		finalRangeActual: number | Dayjs,
		columnFilterActual: string,
		filterRemove: string,
		sortField: string
	): Array<Filter> => {
		const resultFilterToApply = listHelper.generateFiltersToApply(
			typeFilterActual,
			valueFilterActual,
			initRangeActual,
			finalRangeActual,
			columnFilterActual,
			filterRemove,
			sortField,
			purchaseOrderList.dataFilters,
			purchaseOrderList.filtersOfTypeStatus
		) as FilterToApply

		const obj: UpdateFilter = resultFilterToApply.objectForStateUpdate

		setPurchaseOrderList((prevState) => ({
			...prevState,
			dataFilters: obj.dataFilters,
			dataFiltersArray: obj.dataFiltersArray,
			deleteRange: obj.deleteRange,
			filterContainerBar: obj.filterContainerBar
		}))

		if (obj.filtersOfTypeStatus) {
			setPurchaseOrderList((prevState) => ({
				...prevState,
				filtersOfTypeStatus: obj.filtersOfTypeStatus
			}))
		}

		return resultFilterToApply.filterToApply
	}

	const closeContainerBar = () => {
		listHelper.closeFilterContainerBar(filteringPurchaseOrder, initialSort)
		setPurchaseOrderList((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const deleteFilterActive = (indexObject: string) => {
		showLoadingSpinner()
		listHelper.deleteFilterActive(
			indexObject,
			purchaseOrderList.dataFilters,
			closeContainerBar,
			filteringPurchaseOrder
		)
		setPurchaseOrderList((prevState) => ({
			...prevState,
			deleteRange: true
		}))
		hideLoadingSpinner()
	}
	return (
		<>
			<AuthenticatedHeader>
				<div>
					{userRole === Roles.CUSTOMER && (
						<AddButton
							onClick={() => history.push(URLS.PO_NEW)}
							translationKey="newOrder"
							disabled={isNewOrderButtonDisabled}
						/>
					)}
				</div>
				<div>
					<ExportButton onClick={() => downloadReportExcel()} />
				</div>
			</AuthenticatedHeader>
			<PurchaseOrderTable
				dataPurchase={purchaseOrderList.dataPurchaseOrder}
				filteringPurchaseOrder={filteringPurchaseOrder}
				rowsActual={purchaseOrderList.rowsActual}
				totalRows={purchaseOrderList.totalRows}
				filterContainerBar={purchaseOrderList.filterContainerBar}
				closeFilterContainerBar={closeContainerBar}
				dataFiltersArray={purchaseOrderList.dataFiltersArray}
				deleteFilterActive={deleteFilterActive}
				deleteRange={purchaseOrderList.deleteRange}
				userRole={userRole}
				filtersOfTypeStatus={purchaseOrderList.filtersOfTypeStatus}
				setActivePagination={setActivePagination}
				showBarLoader={showBarLoader}
				setShowBarLoader={setShowBarLoader}
			/>
			<CatchedErrorModalPurchaseOrderList open={!!modal.errorCode} errorCode={modal.errorCode} />
		</>
	)
}
