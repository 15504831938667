import { NetcurioChip } from '@netcurio/frontend-components'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { PurchaseRequisition } from '../../types/purchase-requisition'
import { PurchaseRequisitionStatus } from '../../types/purchase-requisition-status'

const severityByStatus: Record<
    PurchaseRequisitionStatus,
    'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
> = {
    [PurchaseRequisitionStatus.NEW]: 'info',
    [PurchaseRequisitionStatus.CANCELED]: 'default',
    [PurchaseRequisitionStatus.APPROVED]: 'success',
    [PurchaseRequisitionStatus.REJECTED]: 'default',
    [PurchaseRequisitionStatus.PENDING]: 'warning',
    [PurchaseRequisitionStatus.UNDER_REVIEW]: 'warning'
}

export const PurchaseRequisitionStatusChip = ({
    purchaseRequisition
}: {
    purchaseRequisition: PurchaseRequisition
}) => {
    const { status } = purchaseRequisition
    const { t } = useTranslation()

    const severity = useMemo(
        () => severityByStatus[status as PurchaseRequisitionStatus] ?? 'default',
        [status]
    )

    return <NetcurioChip label={t(`purchaseRequisitions.status.${status}`)} severity={severity} />
}
