import { currencyFormatter } from '@netcurio/frontend-common'
import {
	NetcurioButton,
	NetcurioDatePicker,
	NetcurioGrid,
	NetcurioIcons,
	NetcurioInputLabel,
	NetcurioSelect,
	NetcurioTextField,
	StepProgressBar,
	StepProgressBarProps,
	TotalContainerBar
} from '@netcurio/frontend-components'
import { MdRefPalette07InfoAMain } from '@netcurio/frontend-design-tokens'
import classNames from 'classnames'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { RouterChildContext, useHistory } from 'react-router-dom'
import { AuthenticatedHeader } from '../../../components/AuthenticatedHeader/AuthenticatedHeader'
import styles from '../../purcharse-orders/newPurchaseOrder/newPurchaseOrder.module.scss'

export const PurchaseRequisitionDetail: FC = () => {
	const { t } = useTranslation()
	const history: RouterChildContext['router']['history'] = useHistory()
	const stepNewArray: StepProgressBarProps[] = [
		{
			stepPosition: 1,
			statusLabel: 'NEW',
			color: MdRefPalette07InfoAMain,
			date: new Date(),
			active: true
		}
	]
	return (
		<NetcurioGrid
			container
			minWidth="100%"
			minHeight="100vh"
			display="grid"
			gridTemplateRows="5.5rem 1fr"
		>
			<AuthenticatedHeader>
				<div>
					<NetcurioButton
						color="error"
						variant={'outlined'}
						onClick={() => history.goBack()}
						size="small"
						endIcon={<NetcurioIcons.CancelOutlined />}
					>
						<span> {t('purchaseReqs.cancel')} </span>
					</NetcurioButton>
					<NetcurioButton
						color="primary"
						variant={'contained'}
						onClick={() => console.log('Clicked Send')}
						size="small"
						endIcon={<NetcurioIcons.Check />}
					>
						<span> {t('purchaseReqs.send')} </span>
					</NetcurioButton>
					<NetcurioButton
						color="primary"
						variant={'outlined'}
						onClick={() => console.log('Clicked Ssve')}
						size="small"
						endIcon={<NetcurioIcons.Save />}
					>
						<span> {t('purchaseReqs.save')} </span>
					</NetcurioButton>
				</div>
				<div>
					<NetcurioButton
						variant="outlined"
						className={styles.btnHeader}
						onClick={() => console.log('Open Attachments')}
						endIcon={
							<NetcurioIcons.AttachFile className={classNames(styles.icon, styles.iconPDF)} />
						}
						// disabled={disableAttachButton}
					>
						<span> {t('attachDocumentsTitle')} </span>
					</NetcurioButton>
				</div>
			</AuthenticatedHeader>
			<NetcurioGrid container alignItems="flex-start" height="100%">
				<NetcurioGrid item xs={9} height="100%" width="100%">
					<NetcurioGrid item container xs={12} height="auto">
						<NetcurioGrid item xs={12} className={styles.mainCardContainer}>
							<p>{t('purchaseReqs.title')}</p>
						</NetcurioGrid>
						<NetcurioGrid
							item
							container
							xs={12}
							className={styles.purchaseOrderInformationSection}
						>
							<NetcurioGrid
								item
								container
								xs={12}
								marginTop={'1rem'}
								marginBottom={'1.5rem'}
								marginLeft={'2rem'}
								spacing={2}
							>
								<StepProgressBar stepProgressArray={stepNewArray} />
							</NetcurioGrid>
							<p className={styles.subtitleModule}>{t('purchaseReqs.dataOf')}</p>
							<NetcurioGrid item container xs={12}>
								<NetcurioGrid item xs={3} paddingRight={'1rem'}>
									<NetcurioInputLabel htmlFor="" shrink={true}>
										{t('purchaseReqs.type')}
									</NetcurioInputLabel>
									<NetcurioSelect value={'Sample'} fullWidth />
								</NetcurioGrid>
								<NetcurioGrid item xs={3} paddingRight={'1rem'}>
									<NetcurioInputLabel htmlFor="" shrink={true}>
										{t('companyNameText')}
									</NetcurioInputLabel>
									<NetcurioTextField value={'Sample'} fullWidth disabled={true} />
								</NetcurioGrid>
								<NetcurioGrid item xs={3} paddingRight={'1rem'}>
									<NetcurioInputLabel htmlFor="" shrink={true}>
										{t('textRFC')}
									</NetcurioInputLabel>
									<NetcurioTextField value={'Sample'} fullWidth disabled={true} />
								</NetcurioGrid>
								<NetcurioGrid item xs={3}>
									<NetcurioInputLabel htmlFor="" shrink={true}>
										{t('purchaseReqs.creationDate')}
									</NetcurioInputLabel>
									<NetcurioDatePicker fullWidth disabled={true} />
								</NetcurioGrid>
								<NetcurioGrid item xs={12} paddingTop={'1rem'}>
									<NetcurioTextField value={t('purchaseReqs.description')} fullWidth />
									<NetcurioInputLabel htmlFor="" shrink={true}>
										{t('max120Char')}
									</NetcurioInputLabel>
								</NetcurioGrid>
							</NetcurioGrid>
							<NetcurioGrid item container xs={12}>
								<NetcurioGrid item container xs={12}>
									<p className={styles.subtitleModule}>
										{t('purchaseReqs.requesterInformation')}
									</p>
								</NetcurioGrid>
								<NetcurioGrid item xs={3} paddingRight={'1rem'}>
									<NetcurioInputLabel htmlFor="" shrink={true}>
										{t('purchaseReqs.createdBy')}
									</NetcurioInputLabel>
									<NetcurioTextField
										value={'Arturo Ramones Cruz'}
										fullWidth
										disabled={true}
									/>
								</NetcurioGrid>
								<NetcurioGrid item xs={3} paddingRight={'1rem'}>
									<NetcurioInputLabel htmlFor="" shrink={true}>
										{t('purchaseReqs.branch')}
									</NetcurioInputLabel>
									<NetcurioSelect value={'Sample'} fullWidth />
								</NetcurioGrid>
								<NetcurioGrid item xs={3} paddingRight={'1rem'}>
									<NetcurioInputLabel htmlFor="" shrink={true}>
										{t('purchaseReqs.requesterArea')}
									</NetcurioInputLabel>
									<NetcurioSelect value={'Sample'} fullWidth />
								</NetcurioGrid>
								<NetcurioGrid item xs={3}>
									<NetcurioInputLabel htmlFor="" shrink={true}>
										{t('purchaseReqs.requester')}
									</NetcurioInputLabel>
									<NetcurioTextField value={'Solicitante'} fullWidth />
								</NetcurioGrid>
								<NetcurioGrid item xs={12} sx={{ marginTop: '1rem' }}>
									<NetcurioInputLabel htmlFor="" shrink={true}>
										{t('purchaseReqs.address')}
									</NetcurioInputLabel>
									<NetcurioTextField
										id={t('status_product')}
										value={''}
										fullWidth
										disabled={true}
									/>
								</NetcurioGrid>
							</NetcurioGrid>
						</NetcurioGrid>
					</NetcurioGrid>
					<NetcurioGrid item container xs={12} height="auto" paddingTop={'1rem'}>
						<NetcurioGrid item container xs={12} className={styles.mainCardContainer}>
							<p>{t('purchaseReqs.positions')}</p>
						</NetcurioGrid>
						<NetcurioGrid
							item
							container
							xs={12}
							className={styles.purchaseOrderInformationSection}
						>
							{/*{purchaseOrder.items.map((item, key) => (
									<div key={key}>
										<Item
											itemPosition={key}
											deleteItem={deleteItem}
											handleItemFieldChange={handleItemFieldChange}
											itemData={item}
											selectedSupplier={selectedSupplier}
											storageLocations={storageLocations}
											selectedProductItem={selectedProductItem}
											totalErrorsByPurchaseOrder={totalErrorsByPO}
											isBranchSelected={!!selectedBranchOffice}
											POFromQuotation={POFromQuotation}
										/>
									</div>
								))}*/}
							<NetcurioButton
								startIcon={<NetcurioIcons.AddCircleOutlineOutlined />}
								onClick={() => console.log('Clicked')}
								variant="text"
							>
								{t('addItem')}
							</NetcurioButton>
						</NetcurioGrid>
					</NetcurioGrid>
				</NetcurioGrid>
				<NetcurioGrid item xs={3} paddingLeft={'1rem'}>
					<NetcurioGrid item paddingBottom={'1rem'}>
						<TotalContainerBar
							title="total"
							fields={{
								currencyText: 'MXN'
							}}
							total={{
								totalDotText: currencyFormatter.format(10000.0)
							}}
						/>
					</NetcurioGrid>
				</NetcurioGrid>
			</NetcurioGrid>
		</NetcurioGrid>
	)
}
