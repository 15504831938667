import { dateFormatter, parseTimestampToTimeZone } from '@netcurio/frontend-common'
import { NetcurioListHeader } from '@netcurio/frontend-components/src/NetcurioList/types'
import { t } from 'i18next'
import React from 'react'
import formatter from '../../../../utilities/formatter'
import { PurchaseRequisition } from '../../types/purchase-requisition'
import { PurchaseRequisitionStatusChip } from '../components/PurchaseRequisitionStatusChip'

export function getPurchaseRequisitionHeaders(): NetcurioListHeader<PurchaseRequisition>[] {
	return [
		{
			title: t('purchaseRequisitions.headers.id'),
			key: 'id',
			filter: {
				type: 'text'
			}
		},
		{
			title: t('purchaseRequisitions.headers.type'),
			key: 'type',
			filter: {
				type: 'text'
			}
		},
		{
			title: t('purchaseRequisitions.headers.status'),
			key: 'status',
			render: (item: PurchaseRequisition) => (
				<PurchaseRequisitionStatusChip purchaseRequisition={item} />
			),
			filter: {
				type: 'options',
				options: [
					{
						label: t('purchaseRequisitions.status.NEW'),
						value: 'NEW'
					},
					{
						label: t('purchaseRequisitions.status.CANCELED'),
						value: 'CANCELED'
					},
					{
						label: t('purchaseRequisitions.status.APPROVED'),
						value: 'APPROVED'
					},
					{
						label: t('purchaseRequisitions.status.REJECTED'),
						value: 'REJECTED'
					},
					{
						label: t('purchaseRequisitions.status.PENDING'),
						value: 'PENDING'
					},
					{
						label: t('purchaseRequisitions.status.UNDER_REVIEW'),
						value: 'UNDER_REVIEW'
					}
				]
			}
		},
		{
			title: t('purchaseRequisitions.headers.description'),
			key: 'description',
			filter: {
				type: 'text'
			}
		},
		{
			title: t('purchaseRequisitions.headers.requester'),
			key: 'requester',
			formatter: (item: PurchaseRequisition) => `${item.requester?.name} ${item.requester?.lastname}`
		},
		{
			title: t('purchaseRequisitions.headers.department'),
			key: 'department',
			filter: {
				type: 'text'
			}
		},
		{
			title: t('purchaseRequisitions.headers.approver'),
			key: 'approver',
			formatter: (item: PurchaseRequisition) => `${item.approver?.name} ${item.approver?.lastname}`
		},
		{
			title: t('purchaseRequisitions.headers.createdAt'),
			key: 'createdAt',
			formatter: (item: PurchaseRequisition) =>
				item.createdAt ? dateFormatter.format(parseTimestampToTimeZone(item.createdAt)) : '-',
			filter: {
				type: 'dateRange'
			}
		},
		{
			title: t('purchaseRequisitions.headers.total'),
			key: 'total',
			formatter: (item: PurchaseRequisition) => formatter.currency.format(Number(item.total))
		},
		{
			title: t('purchaseRequisitions.headers.currency'),
			key: 'currency',
			filter: {
				type: 'text'
			}
		}
	]
}
