import React, { ReactElement } from 'react'
import { parseTimestampToTimeZone, dateFormatter, Roles, URLS } from '@netcurio/frontend-common'
import { Link } from 'react-router-dom'
import { TableRowProps } from './types'
import Formatter from '../../../utilities/formatter'
import Constants from '../../../utilities/constants'
import styles from './purchaseOrderList.module.scss'

export const TableRow = ({ dataPurchase, userRole }: TableRowProps): ReactElement => {
	const isEditPO = userRole !== Roles.CUSTOMER && dataPurchase.status.key === Constants.STATUS.NEW

	return (
		<Link
			className={styles.rowPurchaseOrder}
			to={`${isEditPO ? URLS.PO_EDIT : URLS.PO_DETAIL}?order=${dataPurchase.id}&customer=${
				dataPurchase.customer.rfc
			}`}
		>
			<p className={`${styles.textColumnMainTablePOList} ${styles.fieldId}`}>
				{Formatter.id(dataPurchase.id)}
			</p>
			<p className={`${styles.textColumnMainTablePOList} ${styles.fieldCustomerReference}`}>
				{dataPurchase.customer_reference ? dataPurchase.customer_reference : Constants.SYMBOL.HYPHEN}
			</p>
			<p className={`${styles.textColumnMainTablePOList} ${styles.fieldType}`}>
				{dataPurchase.type.value}
			</p>
			<p className={`${styles.textColumnMainTablePOList} ${styles.fieldStatus}`}>
				{dataPurchase.status.value}
			</p>
			<p className={`${styles.textColumnMainTablePOList} ${styles.fieldBranchOffice}`}>
				{dataPurchase.branch_office}
			</p>
			<p className={`${styles.textColumnMainTablePOList} ${styles.fieldCompanyName}`}>
				{userRole === Roles.CUSTOMER ? dataPurchase.supplier.name : dataPurchase.customer.name}
			</p>
			<p className={`${styles.textColumnMainTablePOList} ${styles.fieldDate}`}>
				{dateFormatter.format(parseTimestampToTimeZone(dataPurchase.created_at))}
			</p>
			<p className={`${styles.textColumnMainTablePOList} ${styles.totalPurchaseOrder}`}>
				{Formatter.currency.format(dataPurchase.total)}
			</p>
		</Link>
	)
}
