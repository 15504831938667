import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

const PURCHASE_REQUISITIONS: DocumentNode = gql`
	query PurchaseRequisitions(
		$limit: Int
		$skip: Int
		$filter: [FilterInput]
		$sortField: String
		$sortOrder: String
	) {
		PurchaseRequisitions(
			skip: $skip
			limit: $limit
			filter: $filter
			sortField: $sortField
			sortOrder: $sortOrder
		) {
			list {
				id
				type
				status
				description
				requester {
					name
					lastname
				}
				department
				approver {
					name
					lastname
				}
				createdAt
				total
				currency
			}
			total
		}
	}
`

export const queries = {
	PURCHASE_REQUISITIONS
}
